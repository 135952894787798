import React from "react";
import ChadwellHeath from "../../images/branch-awards/BPA_Chadwell_Heath_2022.png";
import Chigwell from "../../images/branch-awards/BPA_Chigwell_2023.png";
import Wickford from "../../images/branch-awards/BPA_Wickford_2023.png";
import WoodfordGreen from "../../images/branch-awards/BPA_Woodford_Green_2023.png";

const branchAwardMap = {
    'chadwell-heath': ChadwellHeath,
    'chigwell': Chigwell,
    'wickford': Wickford,
    'woodford-green': WoodfordGreen,
};

const BranchAwardComponent = ( props ) => {
    const imageUrl = branchAwardMap[props.slug];
    if (!imageUrl) {
        // If the slug doesn't match any key in the mapping, return null or another fallback
        return null;
    }
    return(
       <div className="branch-award">
           <img src={imageUrl} alt="" />
        </div>
    );
};

export default BranchAwardComponent;